import React, { Fragment } from "react";
import FAQCard from "../../components/FAQCard";
import "./faq.css";
// import { CSSTransition, TransitionGroup } from "react-transition-group";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
class FAQ extends React.Component {
  state = {
    faqs: [
      {
        id: 0,
        question: "Can you translate the language I want?",
        answer: (
          <Fragment>
            Yes. We translate every common language and have translated many
            dialects from multilingual nations like India, the Philippines and
            China. The best way to find out if we can help you is to call us
            1(800)355-2776 or send us an{" "}
            {
              <a href='mailto:oci@ocinterpreting.com?subject=languages'>
                email
              </a>
            }{" "}
            with the specific language you need.
          </Fragment>
        )
      },
      {
        id: 1,
        question: "How fast can you do it?",
        answer:
          "Very quickly. Depending on the language, certification(s) required, location and other variables, we can provide an interpreter in as little as an hour. Rarely does it take longer than 2 days to find an appropriate resource for translation or interpretation."
      },
      {
        id: 2,
        question: "Do I need a professional translation service?",
        answer:
          "A professional translation service offers experienced translators that understand not just two languages, but the proper etiquette of providing translation and interpretation services. In today's litigious environment, it is imperative that you hire a well-qualified and experienced interpreter."
      },
      {
        id: 3,
        question: "How much should I budget for translation services?",
        answer:
          "O.C. Interpreting offers very competitive rates for our services. Each situation will have a unique combination of variables that combine to form the basis of costs. Important considerations would be the language(s) involved, whether the interpreter needs to be certified, the location of the assignment and if the interpretation will be deeply technical (medical, software, legal) in nature."
      },
      {
        id: 4,
        question: "Can I feel legally safe using your service?",
        answer:
          "We maintain professional liability insurance for errors and omissions. This distinguishes us from many services that merely rely on good fortune."
      }
    ]
  };

  render() {
    const { faqs } = this.state;
    return (
      <div className='hero '>
        <div className='hero-body  has-background-info'>
          <div className='container'>
            <section className='columns section is-multiline is-centered is-paddingless'>
              <div className='box column is-8 content has-text-centered has-background-dark'>
                <ReactCSSTransitionGroup
                  transitionName='example'
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={300}
                  transitionAppear={true}
                  transitionAppearTimeout={500}
                >
                  <h1 className='title is-marginless has-text-light cursor'>
                    Frequently Asked Questions
                  </h1>
                </ReactCSSTransitionGroup>
              </div>

              {faqs.map(faq => (
                <div className='column is-8'>
                  <ReactCSSTransitionGroup
                    transitionName='example'
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}
                    transitionAppear={true}
                    transitionAppearTimeout={500}
                  >
                    <FAQCard
                      key={faq.id}
                      question={faq.question}
                      answer={faq.answer}
                    />
                  </ReactCSSTransitionGroup>
                </div>
              ))}
            </section>
            <section className='section has-text-centered'>
              <ReactCSSTransitionGroup
                transitionName='example'
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}
                transitionAppear={true}
                transitionAppearTimeout={500}
              >
                <p>
                  <strong>
                    If you have any additional questions that aren't answered
                    here, please call our toll-free number:{" "}
                    <span className='has-text-black'>1(800)355-2776</span> or{" "}
                    <span className='has-text-black'>(760)438-9380</span>.
                  </strong>
                </p>
              </ReactCSSTransitionGroup>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
