import React from "react";
import "bulma-pricingtable/dist/css/bulma-pricingtable.min.css";
import "hover.css";
const ServicesList = props => {
  const { services } = props;
  return (
    <div className='pricing-table '>
      <div className='pricing-plan content is-active'>
        <div className='plan-price has-text-center'>
          <h3 className='subtitle '>
            We have certified interpreters and translators with experience in
            written and verbal translations in all areas including:
          </h3>
        </div>

        <div className='plan-items is-variable is-1 is-centered columns'>
          <div className='column'>
            {services.a.map(service => (
              <div className='plan-item '>{service}</div>
            ))}
          </div>
          <div className='column'>
            {services.b.map(service => (
              <div className='plan-item '>{service}</div>
            ))}
          </div>
        </div>
        <div className='plan-footer has-text-center'>
          <p>
            If you need a different kind of translation service that is not
            listed here, feel free to call (Toll-free: 1(800)355-2776) or{" "}
            <a href='mailto:oci@ocinterpreting.com?subject=Other translation services'>
              email us.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesList;
