import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

class Nav extends React.Component {
  render() {
    return (
      <div className='hero-head'>
        <nav
          className='navbar is-fixed-top has-background-dark is-info'
          role='navigation'
          aria-label='main navigation'
        >
          <div className='container'>
            <div className='navbar-brand'>
              <Link to={"/"} className='navbar-item'>
                {"O.C.".split("").map(letter => (
                  <span className='hvr-float '>{letter}</span>
                ))}
                <span className='hvr-float is-invisible'>-</span>
                {"Interpreting".split("").map(letter => (
                  <span className='hvr-float '>{letter}</span>
                ))}
              </Link>

              <a
                href
                role='button'
                className='navbar-burger burger'
                aria-label='menu'
                aria-expanded='false'
                data-target='navbarBasicExample'
              >
                <span aria-hidden='true' />
                <span aria-hidden='true' />
                <span aria-hidden='true' />
              </a>
            </div>

            <div
              id='navbarBasicExample'
              className='navbar-menu has-background-dark'
            >
              <div className='navbar-end has-text-centered '>
                <Link to={"/"} className='navbar-item '>
                  {"Home".split("").map(letter => (
                    <span className='hvr-float '>{letter}</span>
                  ))}
                </Link>
                <Link to={"/services"} className='navbar-item'>
                  {"Services".split("").map(letter => (
                    <span className='hvr-float '>{letter}</span>
                  ))}
                </Link>
                <a href={"#contact"} className='navbar-item'>
                  {"Contact".split("").map(letter => (
                    <span className='hvr-float '>{letter}</span>
                  ))}
                </a>
                <Link to={"/faq"} className='navbar-item'>
                  {"F.A.Q.".split("").map(letter => (
                    <span className='hvr-float '>{letter}</span>
                  ))}
                </Link>
                <a
                  href={"http://oci-scheduler.herokuapp.com"}
                  className='navbar-item'
                >
                  {"Scheduler".split("").map(letter => (
                    <span className='hvr-float '>{letter}</span>
                  ))}
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Nav;
