import React, { Component } from "react";

class SiteMap extends Component {
  state = {};

  render() {
    return (
      <div className="container">
        <br />
        siteMap
      </div>
    );
  }
}

export default SiteMap;
