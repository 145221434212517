import React, { Component } from "react";
import ServicesList from "../../components/ServicesList";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
class Services extends Component {
  state = {
    services: {
      a: [
        "Client Meetings",
        "Adoption Agency Meetings",
        "Correctional Institution Meetings",
        "Public Hearings",
        "School Board Meetings",
        "Special Education Meetings (IEP)",
        "Video Productions",
        "Voice-Over or Dubbing",
        "Subtitles",
        "Medical Findings",
        "Trials",
        "Court Proceedings",
        "Organ Donor Proceedings"
      ],
      b: [
        "Legal Depositions",
        "Legal Contracts",
        "Legal Statements",
        "Immigration Proceedings",
        "Business Conferences",
        "Phone Conferences",
        "Presentations",
        "Recorded Tours",
        "Real Estate Brochures",
        "Technical Documentation",
        "Software Localizations",
        "Medical Transcripts",
        "Business Meeting Transcripts"
      ]
    }
  };

  render() {
    return (
      <div className='hero page'>
        <div className='hero-body  has-background-info'>
          <div className='container'>
            <section className='columns is-centered section is-multiline is-paddingless'>
              <div className='box column is-8 content has-text-centered has-background-dark'>
                <ReactCSSTransitionGroup
                  transitionName='example'
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={300}
                  transitionAppear={true}
                  transitionAppearTimeout={500}
                >
                  <h1 className='title is-marginless has-text-light cursor'>
                    Our Services
                  </h1>
                </ReactCSSTransitionGroup>
              </div>
              <div className='column is-7'>
                <ReactCSSTransitionGroup
                  transitionName='example'
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={300}
                  transitionAppear={true}
                  transitionAppearTimeout={500}
                >
                  <ServicesList services={this.state.services} />
                </ReactCSSTransitionGroup>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
