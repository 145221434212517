import React from "react";
import "hover.css";
const FAQCard = props => {
  return (
    <div className="card has-text-dark hvr-grow-shadow">
      <div className="card-header content is-marginless has-text-centered">
        <h3 className="card-header-title is-centered is-vcentered is-marginless has-text-dark">
          {props.question}
        </h3>
      </div>
      <div className="card-content ">
        <p>{props.answer}</p>
      </div>
    </div>
  );
};

export default FAQCard;
