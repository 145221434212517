import React, { Fragment } from "react";
import "hover.css";
import "./style.css";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
class Home extends React.Component {
  state = {};
  render() {
    return (
      <div id="main" className="hero has-background-info page">
        <div className="hero-body">
          <section className=" section columns is-marginless has-text-centered is-vcentered is-multiline">
            <div className="container">
              <ReactCSSTransitionGroup
                transitionName="example"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}
                transitionAppear={true}
                transitionAppearTimeout={500}
              >
                <div className="column is-12">
                  <figure className="image earth is-block">
                    <img src="./images/earth.svg" alt="earth" />
                  </figure>
                </div>
                <div className="column is-12 content">
                  <h1 className="title is-size-3-mobile is-size-1-desktop has-text-black cursor">
                    O.C. Interpreting Agency
                  </h1>
                  <h2 className="subtitle is-4 is-size-5-mobile has-text-dark cursor">
                    O.C. Interpreting is a full-service interpreting agency with
                    interpreters available in over 150 languages.
                  </h2>
                </div>
              </ReactCSSTransitionGroup>
            </div>
          </section>
          <section className="section columns has-background-light">
            <ReactCSSTransitionGroup
              transitionName="example"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}
              transitionAppear={true}
              transitionAppearTimeout={500}
            >
              <div className="content column is-10 is-offset-1">
                <h1 className="title has-text-centered is-size-3-mobile is-size-1-desktop has-text-black">
                  About Us
                </h1>
                <p>
                  Our interpreters are experienced, reliable, competent and
                  certified in a variety of specializations.{" "}
                  <strong>O.C. Interpreting</strong> offers reasonable rates and
                  always provides prompt, accurate billing. Our office staff is
                  well-regarded as professional, responsive and efficient. For
                  your protection and ours, O.C. Interpreting carries
                  Professional Liability Insurance (Errors and Omissions)
                  appropriate for our business practices.
                </p>
                <p>
                  In 1986, Oscar Campana began his career as an interpreter
                  working for SIU (Special Investigations Unit) and providing
                  medical interpreting. Mr. Campana realized the high value of
                  accurate interpreting services, especially in areas of
                  jurisprudence and founded <strong>O.C. Interpreting</strong>{" "}
                  in 1990. O.C. Interpreting's reputation for quality service
                  led to widespread demand. Today{" "}
                  <strong>O.C. Interpreting</strong> provides services
                  world-wide and around the clock. Many insurance companies,
                  legal firms and other multi-national corporations trust{" "}
                  <strong>O.C. Interpreting</strong> with their most critical
                  translation jobs.
                </p>
              </div>
            </ReactCSSTransitionGroup>
          </section>
        </div>
      </div>
    );
  }
}

export default Home;
