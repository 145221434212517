import React from "react";
import "./footer.css";

const Footer = props => {
  return (
    <footer className='is-fixed-bottom hero-foot section has-background-dark  has-text-light'>
      <a href name='contact' />
      <div className='columns'>
        <div className='column is-3 is-offset-3'>
          <p>
            If you have any questions regarding our translation services, feel
            free to call or email our professional office staff between 8 a.m.
            and 5 p.m. Pacific time, Monday through Friday.
          </p>
        </div>
        <div className='column is-2 is-offset-1'>
          <p>Toll-free: 1(800)355-2776</p>
          <p>Local: 1(760)438-9380</p>
          <p>Fax: 1(760)683-6718</p>
          <p>
            <a className='is-light' href='mailto:oci@ocinterpreting.com'>
              <i className='fas fa-envelope' /> oci@ocinterpreting.com
            </a>
          </p>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-3 is-offset-3 '>
          <p>Written correspondence and packages may be mailed to:</p>
        </div>

        <div className='column is-2 is-offset-1'>
          <p>P.O. Box 130234</p>
          <p>Carlsbad, CA 92013</p>
        </div>
      </div>

      {/* <a href="https://bulma.io">
        <img
          src="https://bulma.io/images/made-with-bulma--semiwhite.png"
          alt="Made with Bulma"
          width="128"
          height="24"
        />
      </a> */}
    </footer>
  );
};

export default Footer;
