import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home/index";
import Services from "./pages/Services/index";
import Contact from "./pages/Contact/index.js";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import FAQ from "./pages/FAQ/index";
import SiteMap from "./pages/SiteMap/index";
import "./App.css";

class App extends Component {
  render() {
    return (
      <Router>
        <div className='hero '>
          <Nav />
          {/* <div className='hero-body  has-background-info'> */}
          <Switch /*location={location}*/>
            <Route exact path='/' component={Home} />
            <Route exact path='/services' component={Services} />
            {/* <Route exact path="/contact" component={Contact} /> */}
            <Route exact path='/faq' component={FAQ} />
            <Route exact path='/sitemap' component={SiteMap} />
          </Switch>

          {/*  </div> */}

          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
